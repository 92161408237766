import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import SectionTitle from "../../../components/shared/section-title";
import Project from "../../../components/project";
import {
  ProjectSectionWrap,
  ProjectTitleContainer,
  ProjectCardWrap,
} from "./project.stc";

const ProjectSection = ({ section, project }) => {
  const homeProjectData = useStaticQuery(graphql`
    query homeProjectQuery {
      sanityHome {
        projects {
          featuredProjects {
            title
            slug {
              current
            }
            isFeatured
            _rawHomeSummary
            client
            category
            _rawImg
            _id
          }
          headline
          tagline
        }
      }
    }
  `);

  const { headline, tagline } = homeProjectData.sanityHome.projects;

  const featuredProjects = homeProjectData.sanityHome.projects.featuredProjects;

  return (
    <ProjectSectionWrap {...section}>
      <ProjectTitleContainer>
      <Link to={`/projects`}> 
       <SectionTitle title={headline} subtitle={tagline} />
      </Link>
      </ProjectTitleContainer>

      <ProjectCardWrap>
        {featuredProjects.map((data, i) => {
          let isEven = i % 2 === 0;
          return (
            <Project
              {...project}
              isHome
              isEven={isEven}
              key={data.slug.current}
              slug={data.slug.current}
              summary={data._rawHomeSummary}
              image={data._rawImg}
              title={data.title}
              client={data.client}
              category={data.category}
            />
          );
        })}
      </ProjectCardWrap>
    </ProjectSectionWrap>
  );
};

ProjectSection.propTypes = {
  section: PropTypes.object,
  project: PropTypes.object,
};

ProjectSection.defaultProps = {
  section: {
    backgroundColor: "#f8f8f8",
  },
  project: {
    mt: "10px",
    responsive: {
      medium: {
        mt: "50px",
      },
    },
  },
};

export default ProjectSection;
